import * as THREE from "three";
import { createPosition } from "./helpers.js";
import {
  barOpts,
  circleOpts,
  ringOpts,
  radius,
  colorDict,
} from "./constants.js";
import linearGradient from "./assets/linearGradient_gray2.png";
import radialGradient from "./assets/radialGradient_gray.png";
// import { statistic } from "../../mock/statistic.js";
import DataLoader from "../../data-interface/data-loader";
import { BELT, ROAD, PROJECT } from "./constants.js";
// Location类 用于在地球上标出城市地点（圆/环/柱）
export default class Location {
  constructor(props) {
    this.data = props.datas;
    // console.log('location data',this.data);
    // 图形颜色
    this.color = props.color;
    // 代表的类型：一带 / 一路 / 地区项目
    this.type = props.type;
    this.obj = {
      bar: new THREE.Object3D(),
      circle: new THREE.Object3D(),
      ring: new THREE.Object3D(),
    };
    // 这是要被监控的组
    this.group = new THREE.Group();
    // 这是不需要被监控的组
    this.otherGroup = new THREE.Group();

    //贴图
    this.linearGradientTexture = this.texture = new THREE.TextureLoader().load(
      linearGradient,
      (texture) => {
        texture.miniFilter = THREE.NearestFilter;
      }
    );
    this.radialGradientTexture = this.texture = new THREE.TextureLoader().load(
      radialGradient,
      (texture) => {
        texture.miniFilter = THREE.NearestFilter;
      }
    );
    //调整贴图方向
    //this.linearGradientTexture.rotation = Math.PI * .5;
    // this.linearGradientTexture.updateMatrix()

    this.cubeMaterial = new THREE.MeshBasicMaterial({
      color: colorDict.locationPole,
      transparent: true,
      side: THREE.DoubleSide,
      alphaMap: this.linearGradientTexture,
      opacity: 0.6,
      blending: THREE.AdditiveBlending,
    });
    this.circleMaterial = new THREE.MeshBasicMaterial({
      color: this.color,
      side: THREE.DoubleSide,
      alphaMap: this.radialGradientTexture,
      transparent: true,
      blending: THREE.AdditiveBlending,
      opacity: 0.8,
    });
    this.ringMaterial = new THREE.MeshBasicMaterial({
      color: this.color,
      side: THREE.DoubleSide,
      alphaMap: this.radialGradientTexture,
      transparent: true,
    });

    this.mesh = {
      bar: [],
      circle: [],
      ring: [],
    };

    this.init();
  }
  init() {
    this.data.forEach((item) => {
      const { lat, lng, id, name } = item;
      const position = createPosition(lat, lng, radius);
      const userData = { id, name };

      // 非一带一路的 画射线， 一带一路的不画
      // if (this.type === PROJECT) {
      //     let barMesh = this.createBar(position, name);
      //     this.mesh.bar.push(barMesh);
      //     this.obj.bar.add(barMesh);
      // }
      let barMesh = this.createBar(position, name);
      this.mesh.bar.push(barMesh);
      this.obj.bar.add(barMesh);

      let circleMesh = this.createCircle(position, userData);
      this.mesh.circle.push(circleMesh);
      this.obj.circle.add(circleMesh);
    });
    this.otherGroup.add(this.obj.bar);
    this.group.add(this.obj.circle);
    // this.group.add(this.obj.ring);
  }
  // 根据该城市的项目数量，计算光柱的高度
  computeBarHeight(name) {
    // 以此为基准计算高度
    const maxPorjectNum = 30;
    let height = 0;
    const heightLevel = [0, 1.5, 2, 2.5, 3, 3.5];
    const statisticOfCity = DataLoader.statistic.filter(
      (item) => item.name === name
    )[0];
    // console.log(statisticOfCity);
    if (statisticOfCity) {
      //TODO: 目前的算法会重复计算，同一个条目可能既是report又是video，需要获得真实的条目数
      const totalNum =
        parseInt(statisticOfCity.report) +
        parseInt(statisticOfCity.video) +
        parseInt(statisticOfCity.activity) +
        parseInt(statisticOfCity.design);
      //   console.log("totalNum", totalNum);
      //   if (totalNum > 5) {
      //     height = (totalNum / maxPorjectNum) * barOpts.depth;
      //   } else {
      //     height = 0.5 * barOpts.depth;
      //   }
      console.log("name", name, "totalNum", totalNum);
      if (totalNum == 0) {
        height = heightLevel[0] * barOpts.depth;
      } else if (0 < totalNum && totalNum <= 5) {
        height = heightLevel[1] * barOpts.depth;
      } else if (5 < totalNum && totalNum <= 10) {
        height = heightLevel[2] * barOpts.depth;
      } else if (10 < totalNum && totalNum <= 15) {
        height = heightLevel[3] * barOpts.depth;
      } else if (15 < totalNum && totalNum < 20) {
        height = heightLevel[4] * barOpts.depth;
      } else {
        height = heightLevel[5] * barOpts.depth;
      }
      //   height = Math.max(totalNum / maxPorjectNum, 1) * barOpts.depth;
    } else {
      height = heightLevel[0] * barOpts.depth;
    }
    return height;
  }
  createBar(position, name) {
    const height = this.computeBarHeight(name);
    // console.group("查看光柱的高度");
    // console.log("name", name, "height", height);
    // console.groupEnd();
    //const geometry = new THREE.BoxGeometry(barOpts.width, barOpts.height, barOpts.depth);
    //光柱使用cylinder
    const geometry = new THREE.CylinderGeometry(
      barOpts.width,
      barOpts.width,
      height,
      3,
      3,
      true
    );
    geometry.rotateX(-Math.PI * 0.5);
    geometry.applyMatrix4(
      new THREE.Matrix4().makeTranslation(0, 0, height * -0.5)
    );
    let meterial = this.cubeMaterial.clone();
    const mesh = new THREE.Mesh(geometry, meterial);
    mesh.position.copy(position);
    mesh.lookAt(new THREE.Vector3(0, 0, 0));
    return mesh;
  }
  createCircle(position, userData) {
    const geometry = new THREE.CircleGeometry(
      circleOpts.radius,
      circleOpts.segments
    );
    // 给geometry附上城市名字与id
    geometry.userData = userData;
    // 给userData加上类型，表示是一带or一路
    geometry.userData.type = this.type;
    let meterial = this.circleMaterial.clone();
    const mesh = new THREE.Mesh(geometry, meterial);
    mesh.position.copy(position);
    mesh.lookAt(new THREE.Vector3(0, 0, 0));
    return mesh;
  }
  animateRing() {}
}
