<template>
    <header>
        <!-- <el-image :src="Text" class="logo"></el-image> -->
        <div class="title">
            <h2>HNU Design</h2>
            <h1>地区文化艺术调研档案库</h1>
        </div>
        <el-button type="info" size="large" class="mybtn" @click="goToAreaSelect">
            <el-icon :size="24">
                <Location />
            </el-icon>
            {{ Store.state.selectedCity ? Store.state.selectedCity : (cityCount + '地区') }}
        </el-button>
    </header>
</template>
<script setup>
import { Location } from "@element-plus/icons-vue";
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const Store = useStore();
const router = useRouter();

// 地区总数：
let cityCount = Store.state.statistic.length;
// 
let count = computed(() => {
    return Store.state.selectedCity == '' ? Store.state.totalCount : Store.getters.cityProjectsCount;
})

const goToAreaSelect = () => router.push('/app/query');

</script>
<style scoped lang="scss">
.logo {
    width: 195px;
    height: auto;
}



header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 16px 20px 16px;
    z-index: 19;

    h1,
    h2 {
        line-height: 1.5em;
        font-family: inherit;
    }

    h1 {
        font-size: 20px;
        font-weight: 550;
    }

    h2 {
        font-size: 16px;
        font-weight: 400;
    }



    div.titles {
        flex: 1;


    }
}

.mybtn {
    height: 44px;
    width: 104px;
}
</style>