import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import { isMobile } from '../utils';
// PC端
import Home from '../views/Home.vue';

// 移动端
import AppHome from '../views/app/AppHome.vue';
import AppIndex from '../views/app/AppIndex.vue';
import AppQuery from '../views/app/AppQuery.vue';
// import AppAreaStat from '../views/app/appAreaStat.vue';
// import AppAreaDetail from '../views/app/appAreaDetail.vue';
// import { useStore } from 'vuex';
// import { nextTick } from 'process';

// const Store = useStore();

// console.log("STORE",Store);

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/main',
    component: Home,
    children: [
      {
        path: 'main',
        component: () => import(/* webpackChunkName: "main" */ '../views/Main.vue')
      }
    ]
  },
  {
    path: '/detail/:id',
    name: 'Detail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Detail.vue')
  },
  // app
  {
    path: '/app',
    redirect: '/app/AppIndex',
    name: 'AppHome',
    component: AppHome,
    children: [
      // 主页
      {
        path: 'index',
        component: AppIndex
      },
      // 根据地区搜索
      {
        path: 'query',
        name: 'AppQuery',
        component: () => import(/* webpackChunkName: "AppQuery" */ '../views/app/AppQuery.vue')
      },
      // 地区搜索后的统计页面
      {
        path: 'areaStat',
        component: () => import(/* webpackChunkName: "AppAreaStat" */ '../views/app/AppAreaStat.vue')
      },
      // 地区详情
      {
        path: 'areaDetail',
        component: () => import(/* webpackChunkName: "AppAreaDetail" */ '../views/app/AppAreaDetail.vue')
      },
      // 在主页点击项目缩略图后，进入该点击的项目介绍，
      {
        path: 'introduction/:id',
        name: 'AppProjectIntroduction',
        component: () => import(/* webpackChunkName: "AppProjectIntroduction" */ '../views/app/AppProjectIntroduction.vue')
      },
      // 在项目介绍中点击查看详情，跳转至项目详情（iframe页面）
      {
        path: 'detail/:id',
        name: 'AppProjectDetail',
        component: () => import(/* webpackChunkName: "AppProjectDetail" */ '../views/app/AppProjectDetail.vue')
      },
    ]
  },
];

// const routesApp = [
//   {
//     path: '/app',
//     redirect: '/app/index',
//   },
//   {
//     path: '/app',
//     name: 'AppHome',
//     component: AppHome,
//     children: [
//       // 主页
//       {
//         path: '/index',
//         component: () => import(/* webpackChunkName: "appIndex" */ '../views/app/appIndex.vue')
//       },
//       // 地区搜索后的统计页面
//       {
//         path: '/areaStat',
//         component: () => import(/* webpackChunkName: "appAreaStat" */ '../views/app/appAreaStat.vue')
//       },
//       // 地区详情
//       {
//         path:'/areaDetail',
//         component: () => import(/* webpackChunkName: "appAreaDetail" */ '../views/app/appAreaDetail.vue')
//       }
//     ]
//   },
//   {
//     path: '/app/query',
//     name: 'AppQuery',
//     component: () => import(/* webpackChunkName: "appQuery" */ '../views/app/appQuery.vue')
//   }
// ];

// const allRoutes = routes.concat(routesApp);

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
});


router.beforeEach((to, from, next) => {
  // alert(`this device is Mobile? ${isMobile}`);
  if (isMobile) { // 移动端设备
    if (to.path.includes('app')) {// 进入移动端页面
      next();
    } else { // 要进入非移动端页面，则跳到移动端首页
      next('/app');
    }
  } else { // pc端设备
    if (to.path.includes('app')) {// 进入移动端页面 跳到pc端首页
      next('/main');
    } else { // 要进入非移动端页面，直接过
      next();
    }
  }
  // next()
})


// router.beforeEach((to, from,next) => {
//   // console.log(to);
//   if (to.path == "/main") {
//     // Store.commit('startAnimation')
//   }
//   else {
//     // Store.commit('stopAnimation')
//   }
//   next()
// })

export default router
